import 'styles/global/404.scss';
import 'styles/global/500.scss';
import 'styles/global/common.scss';
import 'styles/global/grid.scss';
import 'styles/global/layoutcss.scss';
import 'styles/global/formatting.scss';
import 'styles/global/responsiveGrid.scss';
import 'styles/global/libraries.scss';
import 'semantic/dist/semantic.min.css';
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect, createContext } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import { GoogleAnalytics } from "nextjs-google-analytics";

// AppContext used to pass down sidebar props
export const AppContext = createContext({});

export default function MyApp({ Component, pageProps }) {

  // Global state for Semantic sidebar
  const [sidebarConfig, setSidebarConfig] = useState({
    open: false, // toggle start open or close animation
    type: 'MOBILE_MENU', // mobile menu, signup investor etc.
    animationOpenComplete: false
  });

  const handleSidebarConfigChange = (data) => {

    let update = data;
    // update animation state to true when sidebar opens.
    if(data.open) update = {...update, animationOpenComplete: true}
    return setSidebarConfig({...sidebarConfig, ...update});
  }

  //This should be used for animations that are not immediately visible on initial page load (e.g. animations that are triggered by scrolling).
  useEffect(() => {
    const onScroll = () => {
      const easeUps = document.querySelectorAll(".ease-up");
      for (var i = 0; i < easeUps.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = easeUps[i].getBoundingClientRect().top;
        var elementVisible = 50;
    
        if (elementTop < windowHeight - elementVisible) {
          easeUps[i].classList.add("active");
        } else {
          easeUps[i].classList.remove("active");
        }
      }
    };
    // clean up
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('load', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('load', onScroll, { passive: true });
    setTimeout(() => { window.scroll(0,1), 0 }); //Required to ensure all animation components are rendered on initial page load.
    return () => {window.removeEventListener('scroll', onScroll); window.removeEventListener('load', onScroll)};
  }, []);

  //This should be use for immediate animations & quick animations (dashboard, market, business, auction components, etc..) It ensures the component is displayed reliably on intial page load.
  useEffect(() => {
    const easeUps = document.querySelectorAll(".ease-up-immediate, .ease-up-quick");
    easeUps.forEach(element => {
      element.classList.add("active");
    });
  }, []);

  return (
    <AppContext.Provider value={{handleSidebarConfigChange, sidebarConfig}}>
      <ToastContainer 
        position='bottom-center'
        autoClose={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        closeButton={true}
      />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>
      </Head>

      <Component {...pageProps}/>
      
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N5N7KS3"height="0" width="0" style="display:none;visibility:hidden"></iframe>	`,
        }}
      />

      <GoogleAnalytics trackPageViews gaMeasurementId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS} />
      
      <Script
        id="HotJarAnalytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1895031,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;    
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `
        }}
      >
      </Script>

    </AppContext.Provider>
  )
}